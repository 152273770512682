.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../img/movies.jpg");
    background-size: cover;
}

.login-title{
    color: #CAE962;
    font-size: 3vh;
    position: relative;
    right: 5vw;
}

.login-link{
    color: white;
    font-size: 3vh;
    position: relative;
    right: 4.5vw;
}

.logo1{
    text-decoration: none;
    font-family: bold itati;
    text-align: center;
    margin-bottom: 1vh;
    color: #CAE962;
    font-weight: bolder;
    font-size: 4.5vh;
    text-shadow: 1vh 0.8vh 10px #e5e9d9;
    position: relative;
    bottom: 40vh;
    left: -32vw
}

.searchbutton{
    border-radius: 20px;
    height: 5.5vh;
    width: 10vh;
    margin-left: 1vh;
    margin-top: 0.5vh;
    background-color: #CAE962;
    outline: none;
    border: none;
}

.searchbutton:hover{
    background-color: black;
    color: #CAE962;
    border: solid #CAE962 1px;
}