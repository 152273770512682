.star-container{
    display: flex;
    flex-direction: column;
    
    
}

.review-container{
    display: flex;
    justify-content: space-between;
    margin-right: 1vw;
    margin-left: 1vw;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.rating-star{
    display: flex;
    flex-direction: column;
}

.user-count{
    color: white;
    display: flex;
    align-items: center;
    font-size: 2.7vh;
}

.ratemovie{
    color: white;
    font-size: 2.7vh;
    margin-left: 1vw;
}

.mstar{
    display: flex;
    align-items: center;
    background-color: #505050;
    border-radius: 1vh;
    box-shadow: -0.1vw 0.1vh 1em #505f1d,
                0.5vw -0.5vh 1em #82973c;
}

.mstar button{
    margin-right: 1vw;
    margin-bottom: 0.5vh;
    background-color: transparent;
    border: none;
    width: 4.5vh;
    font-size: 5vh;
}

.star{
    background-color: transparent;
    border: none;
    width: 4.5vh;
    font-size: 5vh;
}

.rate-button{
    margin-left: 1vh;  
}