.body-container{
    height: 70vh;
    width: 100%;
}

.movie-box{
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
}

.movie-container{
    height: 40vh;
    width: 30vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5vh;
    border-radius: 3vh;
}

.movie-container:hover{
    box-shadow: -0.5vw 0.5vh 1em #e6e9db,
                0.5vw -0.5vh 1em #8eb110
}

.poster-container{
    height: 35vh;
    width: 30vh;
    
}

.poster{
    height: 35vh;
    width: 25vh;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    border-top-left-radius: 5vh;
}

.title{
    height: 4.5vh;
    width: 25vh;
    font-size: 2vh;
    text-align: center;
    color: white;
    background-color: #5a5a5a;
    margin-left: 2.3vh;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
}

.title:hover{
    color: #CAE962;
}

.topic{
    color: #CAE962;
    font-size: 4.5vh;
    margin-left: 5vh;
    margin-bottom: 2.5vh;
}

.nomovies{
    text-align: center;
    color: #CAE962;
}