html{
    background-color: #363636;    
}


.header-container{
    height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sub-header-container{
    display: flex;
    justify-content: space-between;
    margin-left: 4.5vh;
    margin-right: 4.5vh;
}

.serachbar-container{
    display: flex;
    height: 100%;
    width: 100%; 
    border: solid black 2px;
}

.search-block{
    height:10vh;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2vh;
}

.searchbar{
    border-radius: 20px;
    height: 5vh;
    width: 40vh;
    padding-left: 3vh;
    padding-right: 3vh;
    margin-bottom: 0.5vh;
}

.searchbar:active{
    border: solid #CAE962 5px;
}

.searchbutton{
    border-radius: 20px;
    height: 5.5vh;
    width: 10vh;
    margin-left: 1vh;
    margin-top: 0.5vh;
    background-color: #CAE962;
    outline: none;
    border: none;
}

.searchbutton:hover{
    background-color: black;
    color: #CAE962;
    border: solid #CAE962 1px;
}

.logo{
    text-decoration: none;
    font-family: bold itati;
    text-align: center;
    margin-bottom: 1vh;
    color: #CAE962;
    font-weight: bolder;
    font-size: 4.5vh;
    text-shadow: 1vh 1vh 10px #000000;
}


