.login-form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form{
    border: solid #50553e 0.5vh;
    padding: 5vh 3vw;
    border-radius: 3vh;
    box-shadow: -0.5vw 0.5vh 1em #e6e9db,
                0.5vw -0.5vh 1em #8eb110;
}

.login-button{
    border-radius: 20px;
    height: 5.5vh;
    width: 10vh;
    margin-left: 1vh;
    margin-top: 0.5vh;
    background-color: #CAE962;
    outline: none;
    border: none;
}

a{
    text-decoration: none;
}

.new-login{
    color: white;
    font-size: 2.5vh;
}

.new-login:hover{
    color: #CAE962;
    font-size: 2.5vh;
    cursor: pointer;
}

label{
    font-size: 3vh;
    color: #CAE962;
    margin-left: 1vw;
}

.input-field{
    margin-bottom: 1vh;
}

.input-field input{
    border-radius: 3vh;
    height: 4vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.button-field{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5vh;
}