.bodys-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.movies-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.detail-poster{
    height: 100%;
    width: 50vh;
    margin-right: 3vh;
    border-radius: 2vh;
    border:solid #CAE962 1px;
}

.details{
    width: 145vh;
    color: white;
}

.comment-container{
    color: white;
    display: flex;
    justify-content: center;
}

.comment{
    border: solid #50553e 0.5vh;
    border-radius: 2vw;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-top: 1vh;
    width: 100%;
    box-shadow: -0.5vw 0.5vh 1em #e6e9db,
                0.5vw -0.5vh 1em #8eb110;
}

.comment-input{
    width: 100%;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 1.5vh;
    display: flex;
    flex-direction: row;
}

.comment-input input{
    width: 83%;
    height: 4vh;
    border-radius: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    border: none;
    margin-right: 0.3vw;
}

.comment-input input:active{
    border: solid white 5px
}

.comment-input button{
    
    font-size: 3vh;
    border-radius: 1vh;
    border: none;
    background-color: white;
}

.comment-input button:hover{
    box-shadow: -0.1vw -0.1vh 5px #e6e9db,
                0.1vw -0.1vh 5px #8eb110;
}

.e-comment{
    display: flex;
    flex-direction: column-reverse;
    margin-left: 1vw;
    margin-right: 1vw;
}

.e-comment p{
    color: #f3fbaa;
}

.e-comment hr{
    border: solid #50553e 1px;
}